.generator-container {
  width: 100%;
  text-align: center;
  overflow-x: auto;
  .fence-container {
    .fence-config {
      .fence-ready {
        display: inline-flex;
        padding: 12px;
      }
    }
  }
  .remove-fence-btn {
    width: 150px;
    margin: 20px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #6c757d;
    &:hover {
      background-color: #6c757d;
      color: #fff;
    }
  }
  .btn-container {
    text-align: left;
    margin-left: 100px;
  }
  .generated-fence {
    width: 100%;
    position: relative;
    overflow: inherit;
    .fence-container {
      margin: 10px 50px 10px 100px;
      .generated-fence-container {
        display: inline-flex;
        justify-content: center;
        position: relative;
        .generated-fence-ready {
          width: fit-content;
          display: inline-flex;
          padding: 0;
          align-items: baseline;
          justify-content: center;
          .post-data {
            visibility: hidden;
            width: 0;
          }
          .fence-content {
            .fence-ready {
              padding: 0;

              .handleIcon {
                width: 20px;
                height: 20px;
                position: absolute;
              }
            }
          }
          &:hover .generated-fence-btn {
            display: block;
          }
          .generated-fence-btn {
            display: none;
            position: absolute;
            margin-top: 52px;
            .remove-single-fence-btn {
              background-color: #fff;
              border: 1px solid #6c757d;
              color: #6c757d;
              &:hover {
                background-color: #6c757d;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@import "./Global.scss";
.news {
    .list-group-item.active {
        background: $lightGrey;
        color: $bordo;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }
    .pdf-container {
        position: relative;
        .news-pdf {
            canvas {
                width: 80% !important;
                height: auto !important;
                margin: 0 auto;
            }
        }
        .pdf-page-left {
            position: absolute;
            top: 0;
            height: 100%;
            width: 10%;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.125);
            i {
                font-size: 40px;
                color: #fff;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
        .pdf-page-right {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.125);
            i {
                font-size: 40px;
                color: #fff;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

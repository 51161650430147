.fences {
  .fence-content {
    width: "fit-content";
    margin: "0 auto";
    p {
      display: none;
    }
    .fence-ready {
      position: relative;
      .handleIcon {
        width: 20px;
        height: 20px;
        position: absolute;
      }
    }
  }
}

.configuration-container {
  height: 500px;
  .configuration-header {
    margin-bottom: 50px;
    text-align: center;
  }
  .config-box {
    .btn-container {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      .btn-secondary {
        width: 175px;
        background-color: #6c757d;
        border: 1px solid #6c757d;
        border-radius: 5px;
        margin: 5px;
        &:hover {
          color: #6c757d;
          background-color: #fff;
        }
      }
    }
    .ready-config-box {
      display: inline-flex;
      position: relative;
      .handleIcon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 45%;
      }
    }
  }
}

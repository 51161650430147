@import "./Global.scss";
.navigation {
    .navbar-brand {
        margin-left: 80px;
    }
    .logo {
        height: 60px;
    }
}
.sidebar {
    position: fixed;
    top: 0;
    z-index: 100;
    transition: all 0.5s linear;
    height: 100%;
    background: #6c757d;
    overflow-y: auto;
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .sidebar-link {
        transition: 0.3s;
        .sidebar-icon {
            i {
                font-size: 40px;
                color: #fff;
                transition: 0.3s;
            }
        }
        .sidebar-text {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            p {
                margin: 0;
                color: #fff;
                transition: 0.3s;
            }
        }
        .sidebar-icon {
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
        }
        .sidebar-box {
            display: flex;
            justify-content: space-between;
        }
        &:hover {
            background: #f8f9fa;
            .sidebar-icon {
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                i {
                    color: #b82324;
                }
            }
            .sidebar-text {
                p {
                    margin: 0;
                    color: #b82324;
                }
            }
        }
    }
    .active {
        .sidebar-link {
            background: #f8f9fa;
            .sidebar-icon {
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                i {
                    color: #b82324;
                }
            }
            .sidebar-text {
                p {
                    margin: 0;
                    color: #b82324;
                }
            }
        }
    }
}
.sidebar-wide {
    transition: all 0.3s linear;
    width: 250px;
}
.sidebar-narrow {
    transition: all 0.3s linear;
    width: 70px;
}
// .ps {
//     position: fixed;
//     top: 0;
// }
.BurgerSlider:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #b82324;
    border: 0px none #ffffff;
    border-radius: 47px;
}
::-webkit-scrollbar-thumb:hover {
    background: #961f1f;
}
::-webkit-scrollbar-thumb:active {
    background: #000000;
}
::-webkit-scrollbar-track {
    background: #f8f9fa;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: #f8f9fa;
}
::-webkit-scrollbar-track:active {
    background: #333333;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

@import "./Global.scss";
.home-page {
    // background: #ededed;
    .partners {
        .partners-card {
            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .partners-icon {
                width: 10rem;
            }
            a {
                &:hover {
                    text-decoration: none;
                }
                color: $bordo;
            }
            .card-footer {
                background-color: $lightGrey;
                &:hover {
                    // animation: flash 0.7s ease-out;
                    background-color: #fff;
                }
            }
        }
    }
}
